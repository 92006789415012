
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableColumn, TableDefinition } from '@/components/table/table.types';
import debounce from 'lodash/debounce';

@Component({
  components: {},
})
export default class DataTable<T> extends Vue {
  @Prop({ type: Object, required: true }) definition!: TableDefinition<T>;
  @Prop({ type: Number, default: 0 }) fixedColumns!: number;
  @Prop({ type: String, default: 'auto' }) minWidth!: string;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Boolean, default: false }) expand!: boolean;
  @Prop({ type: Boolean, default: false }) borderless!: boolean;

  get columns(): TableColumn<T>[] {
    return this.definition.columns;
  }

  get rows(): T[] {
    return this.definition.data;
  }

  expandedRows: Record<string, boolean> = {};

  toggle(row: T) {
    const v = row[this.definition.rowKey] + '';
    const expand = !this.expandedRows[v];
    this.$set(this.expandedRows, v, expand);
  }

  get tableStyle() {
    return {
      minWidth: this.minWidth,
    };
  }

  get expandedCellStyle() {
    return {
      width: this.wrapperWidth + 'px',
    };
  }

  get tableClasses() {
    return {
      dense: this.dense,
    };
  }

  get fixedColumnStyles() {
    if (this.fixedColumns === 0) {
      return {};
    }
    if (this.fixedColumns === 1) {
      return [
        {
          left: 0,
        },
      ];
    }
    const out = this.widths.map(w => {
      return {
        left: w + 'px',
      };
    });
    out.pop();
    return out;
  }

  widths: number[] = Array(this.fixedColumns + 1).fill(0);

  observer: ResizeObserver = new ResizeObserver(
    debounce(entries => {
      let total = 0;
      for (let i = 0; i < entries.length; i++) {
        total += (entries[i].target as HTMLElement)?.offsetWidth;
        if (i === entries.length - 1) {
          this.$set(this.widths, i + 1, total);
        } else {
          this.widths[i + 1] = total;
        }
      }
    }, 500),
  );

  wrapperWidth = 0;
  wrapperObserver: ResizeObserver = new ResizeObserver(
    debounce(entries => {
      this.wrapperWidth = (entries[0].target as HTMLElement)?.offsetWidth;
    }, 500),
  );

  $refs!: {
    // it's defined in lib.dom.d.ts 100%
    // eslint-disable-next-line no-undef
    th: HTMLTableHeaderCellElement[] | undefined;
    wrapper: HTMLElement | undefined;
  };

  mounted() {
    this.$nextTick(() => {
      if (this.fixedColumns > 1) {
        let total = 0;
        const arr = this.$refs.th?.slice(0, this.fixedColumns);
        arr?.forEach((th, i) => {
          this.observer.observe(th, { box: 'border-box' });
          total += th.offsetWidth;
          if (i === arr?.length - 1) {
            this.$set(this.widths, i + 1, total);
          } else {
            this.widths[i + 1] = total;
          }
        });
      }
      if (this.expand && this.$refs.wrapper) {
        this.wrapperObserver.observe(this.$refs.wrapper, {
          box: 'border-box',
        });
        this.wrapperWidth = this.$refs.wrapper.offsetWidth;
      }
    });
  }
}
